import React from "react"
import LandingPage from "../LandingPageMaterial/Layout/LandingPage"
import { styled } from "@mui/material"
import { redirectTo } from '@reach/router'

//styled div with styled mui 5

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  height: '100vh',
  width: '100wh',
  justifyContent: "center",
  alignItems: "center",
}))




export default function notFoundPage(){
  return (
    <LandingPage noChatBot>
      <StyledDiv>
        <h2>Pagina no encontrada!</h2>
      </StyledDiv>
    </LandingPage>
  )
}
